import { QpAql } from '@library/classes/qp-aql/qp-aql';
import { EMeasurementSamplingTypes } from '@library/dto/checklist/measure/simplified/measurement-sampling.interface';
import { AqlValue } from '@library/dto-enums/aql-value.dto-enum';
import { EQpAQLInspectionLevel, IQpAQLSamplingSize } from '@library/models/qp-aql.models';
import { EInspectionResult } from '@one/app/pages/brd/pages/report/pages/inspection/pages/id/brd-report-inspection-id.models';
import { IspWorkflowStepAction } from '@one/app/pages/isp/pages/inspection/pages/id/shared/classes/isp-workflow-step-action';
import {
  EWorkflowActionType,
  ISimplifiedMeasurementChecklist,
  IWorkflow,
  IWorkflowAction,
} from '@one/app/shared/models/workflow/workflow.models';
import { IWorkflowInspection } from '@one/app/shared/models/workflows/workflow.models';
import {
  EMeasurementOverallResultCalculationMethod,
  EWorkflowTemplateStepActionInspectionPreparationCalculationMethod,
  EWorkflowTemplateStepName,
} from '@one/app/shared/models/workflows/workflows-templates.models';
import { QimaOptionalType } from '@qima/ngx-qima';

export class IspWorkflowStepActionSimplifiedMeasurementChecklist extends IspWorkflowStepAction {
  public type = EWorkflowActionType.SIMPLIFIED_MEASUREMENTS_CHECKLIST;
  private readonly _sampleSize: QimaOptionalType<IQpAQLSamplingSize>;
  private readonly _action: QimaOptionalType<IWorkflowAction>;

  protected get _isOngoing(): boolean {
    return this.result === EInspectionResult.PENDING;
  }

  public get result(): EInspectionResult {
    return this._action?.result ?? EInspectionResult.PENDING;
  }

  public constructor(action: Readonly<IWorkflowAction>, inspection: Readonly<IWorkflowInspection>, workflow?: Readonly<IWorkflow>) {
    super(action);
    this._action = action;
    this._sampleSize = this.getSampleSize(inspection, workflow);
  }

  public get simplifiedMeasurement(): QimaOptionalType<ISimplifiedMeasurementChecklist> {
    return this._action?.simplifiedMeasurement;
  }

  public getSampleSize(inspection: Readonly<IWorkflowInspection>, workflow?: Readonly<IWorkflow>): QimaOptionalType<IQpAQLSamplingSize> {
    if (!this.simplifiedMeasurement?.sampling) {
      return undefined;
    }

    if (this.simplifiedMeasurement.sampling.type === EMeasurementSamplingTypes.FIXED) {
      return { sampleSize: this.simplifiedMeasurement.sampling.sampleSize } as IQpAQLSamplingSize;
    }

    if (!this.simplifiedMeasurement.sampling.inspectionLevel) {
      return undefined;
    }

    const isMeasurementSamplingMethodFlexible: boolean = this.simplifiedMeasurement.sampling.type === EMeasurementSamplingTypes.FLEXIBLE;
    const inspectionPreparationStep = workflow?.steps
      .find((step): boolean => step.name === EWorkflowTemplateStepName.DURING_INSPECTION)
      ?.actions.find((step): boolean => step.type === EWorkflowActionType.INSPECTION_PREPARATION);
    const workflowMethod = inspectionPreparationStep?.calculationMethod;
    let quantity = 0;

    if (workflowMethod === EWorkflowTemplateStepActionInspectionPreparationCalculationMethod.PACKED_QUANTITY) {
      quantity = inspectionPreparationStep?.productQuantities?.reduce((count, product): number => count + product.produced, 0) ?? 0;
    } else {
      quantity = inspection?.products?.reduce((count, product): number => count + product.productQuantity, 0) ?? 0;
    }

    const aqlValue: AqlValue = this.simplifiedMeasurement.sampling.aql
      ? AqlValue[this.simplifiedMeasurement.sampling.aql]
      : AqlValue.NOT_ALLOWED;
    const aql = new QpAql({
      inspectionLevel: this.simplifiedMeasurement.sampling.inspectionLevel as unknown as EQpAQLInspectionLevel,
      criticalAQL: isMeasurementSamplingMethodFlexible ? AqlValue.NOT_ALLOWED : aqlValue,
      majorAQL: isMeasurementSamplingMethodFlexible ? AqlValue.NOT_ALLOWED : aqlValue,
      minorAQL: isMeasurementSamplingMethodFlexible ? AqlValue.NOT_ALLOWED : aqlValue,
      quantity,
    });

    return aql.criticalSamplingSize;
  }

  public getOutOfToleranceCount(): QimaOptionalType<number> {
    return this.getMeasurementCalculationMethod() === EMeasurementOverallResultCalculationMethod.POINTS_OF_MEASUREMENT_OUT_OF_TOLERANCE
      ? this._action?.simplifiedMeasurement?.measurement?.outOfTolerancePointsOfMeasurement
      : this._action?.simplifiedMeasurement?.measurement?.outOfToleranceSamples;
  }

  public getTotalCount(): QimaOptionalType<number> {
    return this.getMeasurementCalculationMethod() === EMeasurementOverallResultCalculationMethod.POINTS_OF_MEASUREMENT_OUT_OF_TOLERANCE
      ? this._action?.simplifiedMeasurement?.measurement?.pointsOfMeasurement
      : this._sampleSize?.sampleSize;
  }

  public getMeasurementCalculationMethod(): EMeasurementOverallResultCalculationMethod {
    return (
      this._action?.measurementOverallResultCalculationMethod ??
      EMeasurementOverallResultCalculationMethod.POINTS_OF_MEASUREMENT_OUT_OF_TOLERANCE
    );
  }
}
