import { InspectionLevel } from '@library/dto-enums/inspection-level.dto-enum';

/**
 * @description This DTO reflects `com.qima.platform.domain.inspection.performing.checklist.measurement.simplified.sampling`
 * Not an actual java dto, but a back interface used for some calls
 */
export interface IMeasurementSampling {
  type: EMeasurementSamplingTypes;
  sampleSize?: number;
  acceptPoint?: number;
  inspectionLevel?: InspectionLevel;
  aql?: string;
  acceptPointRatio?: number;
}

export enum EMeasurementSamplingTypes {
  AQL = 'AQL',
  FIXED = 'FIXED',
  FLEXIBLE = 'FLEXIBLE',
}
